import React, { useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import { useNavigate } from 'react-router-dom';
import './Tube.css';
import logoImg from './Gold2.png';

gsap.registerPlugin(ScrollTrigger);

function Tube() {
    const canvasRef = useRef(null);
    const navigate = useNavigate();
    const [popupContent, setPopupContent] = useState(null); // State to control popup content
    const [showPopup, setShowPopup] = useState(false); // State to control popup visibility

    const handlePopupOpen = (text, url) => {
        setPopupContent({ text, url });
        setShowPopup(true);
    };

    useEffect(() => {
        const fontLink = document.createElement('link');
        fontLink.href =
            'https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap';
        fontLink.rel = 'stylesheet';
        document.head.appendChild(fontLink);

        const canvas = canvasRef.current;
        const ww = window.innerWidth;
        const wh = window.innerHeight;

        const renderer = new THREE.WebGLRenderer({
            canvas,
            antialias: true,
        });
        renderer.setSize(ww, wh);

        const scene = new THREE.Scene();
        scene.fog = new THREE.Fog(0x194794, 0, 100);

        const camera = new THREE.PerspectiveCamera(45, ww / wh, 0.001, 200);
        camera.position.set(0, 0, 200);
        scene.add(camera);

        const renderScene = new RenderPass(scene, camera);
        const bloomPass = new UnrealBloomPass(
            new THREE.Vector2(window.innerWidth, window.innerHeight),
            1.5,
            0.4,
            0.85
        );
        bloomPass.renderToScreen = true;

        const composer = new EffectComposer(renderer);
        composer.setSize(window.innerWidth, window.innerHeight);
        composer.addPass(renderScene);
        composer.addPass(bloomPass);

        const points = [
            [10, 89, 0],
            [50, 88, 10],
            [76, 139, 20],
            [126, 141, 12],
            [180, 44, 5],
            [207, 35, 10],
            [232, 36, 0],
        ].map(([x, z, y]) => new THREE.Vector3(x, y, z));

        const path = new THREE.CatmullRomCurve3(points);
        path.tension = 0.5;

        const tubeGeometry = new THREE.TubeGeometry(path, 300, 4, 32, false);
        const textureLoader = new THREE.TextureLoader();
        const texture = textureLoader.load(
            'https://s3-us-west-2.amazonaws.com/s.cdpn.io/68819/3d_space_5.jpg',
            (texture) => {
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                texture.offset.set(0, 0);
                texture.repeat.set(15, 2);
            }
        );

        const mapHeight = textureLoader.load(
            'https://s3-us-west-2.amazonaws.com/s.cdpn.io/68819/waveform-bump3.jpg',
            (texture) => {
                texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
                texture.offset.set(0, 0);
                texture.repeat.set(15, 2);
            }
        );

        const tubeMaterial = new THREE.MeshPhongMaterial({
            side: THREE.BackSide,
            map: texture,
            shininess: 20,
            bumpMap: mapHeight,
            bumpScale: -0.03,
            specular: 0x0b2349,
        });

        const tube = new THREE.Mesh(tubeGeometry, tubeMaterial);
        scene.add(tube);

        const innerTubeGeometry = new THREE.TubeGeometry(
            path,
            150,
            3.4,
            32,
            false
        );
        const geo = new THREE.EdgesGeometry(innerTubeGeometry);
        const wireframeMaterial = new THREE.LineBasicMaterial({
            linewidth: 2,
            opacity: 0.2,
            transparent: true,
        });

        const wireframe = new THREE.LineSegments(geo, wireframeMaterial);
        scene.add(wireframe);

        const light = new THREE.PointLight(0xffffff, 0.35, 4, 0);
        scene.add(light);

        const imageTexture = textureLoader.load(
            logoImg,
            () => {
                const imageMaterial = new THREE.SpriteMaterial({
                    map: imageTexture,
                });
                const imageSprite = new THREE.Sprite(imageMaterial);
                const endPosition = path.getPointAt(1);
                imageSprite.position.set(
                    endPosition.x,
                    endPosition.y,
                    endPosition.z
                );
                imageSprite.scale.set(10, 5, 1);
                scene.add(imageSprite);
            },
            undefined,
            (error) => {
                console.error(
                    'An error occurred while loading the image:',
                    error
                );
            }
        );

        const createTextSprite = (
            text,
            percentage,
            url,
            content,
            scale = { x: 10, y: 5, z: 1 },
            disableButton = false
        ) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = 1024;
            canvas.height = 512;
            context.font = 'Bold 80px Poppins';
            context.fillStyle = 'white';
            context.textAlign = 'center';
            context.textBaseline = 'middle';
            context.clearRect(0, 0, canvas.width, canvas.height);
            context.fillText(text, canvas.width / 2, canvas.height / 2);

            const texture = new THREE.CanvasTexture(canvas);
            texture.minFilter = THREE.LinearFilter;

            const material = new THREE.SpriteMaterial({
                map: texture,
                fog: true,
                transparent: true,
                opacity: 0.9,
            });

            const sprite = new THREE.Sprite(material);

            const position = path.getPointAt(percentage);
            const tangent = path.getTangentAt(percentage).normalize();
            const up = new THREE.Vector3(0, 1, 0);
            const normal = new THREE.Vector3()
                .crossVectors(tangent, up)
                .normalize();

            sprite.position.copy(position);
            sprite.up.copy(normal);
            sprite.lookAt(position.clone().add(tangent));
            sprite.scale.set(scale.x, scale.y, scale.z);
            const offset = 0;
            sprite.position.add(normal.multiplyScalar(-offset));

            if (!disableButton) {
                sprite.onClick = () => {
                    setPopupContent({ text, url, content });
                    setShowPopup(true);
                };
            } else {
                // Optionally, you could add a different visual style for disabled buttons
                material.opacity = 0.5; // Makes the sprite appear semi-transparent
            }

            scene.add(sprite);
        };

        createTextSprite(
            'Workshop',
            0.1,
            '/elan',
            `ELAN'24 kicks off with an exciting workshop on "Career Pathways in Business and Technology." Dive into the world where business meets tech with insights from an experienced speaker who’s actively shaping the industry today. This event is your gateway to understanding the future of work and how to navigate it effectively. Join us on 13th September, 2024 to launch your journey into the future of work!`,
            { x: 11, y: 6, z: 1 }
        );
        createTextSprite(
            'Odyssey',
            0.3,
            '/elan',
            `Gear up for ELAN 24’s electrifying Valorant tournament, Odyssey! on 13th September, 2024. As the second event in the lineup, this online battle promises high-octane action and fierce competition. Whether you're a sharpshooter or a strategist, it’s your chance to dominate the arena and claim victory. Get ready to lock and load—let the games begin!`,
            { x: 10, y: 5, z: 1 }
        );

        createTextSprite(
            'Tricks Of Trade 2.0',
            0.6,
            '/elan',
            `Step into the fast-paced world of stock trading with Tricks of Trade 2.0 on 14th September, 2024! This thrilling simulation event lets you experience the highs and lows of the stock market, testing your instincts and strategies. Whether you're a budding investor or a market maven, it’s your chance to outsmart the competition and rise to the top. Get ready to trade your way to victory!`,
            { x: 8, y: 4, z: 0.5 }
        );

        createTextSprite(
            'Treasure Hunt',
            0.78,
            '/elan',
            `Where(abouts): The Curse of Starling Vale is the flagship event of ELAN 24, taking place on September 15th, 2024. Participants will navigate hidden clues and challenging puzzles to lift the ancient curse on Starling Vale. Gather your team, trust your instincts, and embark on this thrilling treasure hunt where only the sharpest minds will prevail.`,
            { x: 8, y: 4, z: 0.5 }
        );

        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();

        window.addEventListener('click', (event) => {
            mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
            mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObjects(scene.children);

            for (const intersect of intersects) {
                if (intersect.object.onClick) {
                    intersect.object.onClick();
                }
            }
        });

        const updateCameraPercentage = (percentage) => {
            const p1 = path.getPointAt(percentage % 1);
            const p2 = path.getPointAt((percentage + 0.03) % 1);

            camera.position.set(p1.x, p1.y, p1.z);
            camera.lookAt(p2);
            light.position.set(p2.x, p2.y, p2.z);
        };

        let cameraTargetPercentage = 0;
        const tubePerc = { percent: 0 };

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.scrollTarget',
                start: 'top top',
                end: 'bottom bottom',
                scrub: 1,
            },
        });

        tl.to(tubePerc, {
            percent: 0.96,
            ease: 'none',
            duration: 10,
            onUpdate: () => {
                cameraTargetPercentage = tubePerc.percent;
            },
        });

        const render = () => {
            updateCameraPercentage(cameraTargetPercentage);
            composer.render();
            requestAnimationFrame(render);
        };

        requestAnimationFrame(render);

        window.addEventListener('resize', () => {
            const width = window.innerWidth;
            const height = window.innerHeight;

            camera.aspect = width / height;
            camera.updateProjectionMatrix();

            renderer.setSize(width, height);
            composer.setSize(width, height);
        });
    }, [navigate]);

    return (
        <div>
            <canvas ref={canvasRef} className="experience"></canvas>
            <div className="scrollTarget"></div>
            {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <button
                            className="popup-close"
                            onClick={() => setShowPopup(false)}
                        >
                            &times;
                        </button>
                        <p className="title-event">{popupContent.text}</p>
                        <p className="event-content">{popupContent.content}</p>
                        <button
                            className="popup-link"
                            disabled={popupContent.text === 'Revealing Soon'}
                            style={{
                                display: 'inline-block',
                                marginTop: '20px',
                                padding: '10px 20px',
                                color: '#fff',
                                background: '#520d0d',
                                textDecoration: 'none',
                                borderRadius: '10px',
                                border: '2px solid gold',
                                fontFamily: 'sans-serif',
                                transition: 'background-color 0s',
                                boxShadow: '0 0 10px rgba(255, 215, 0, 0.6)',
                                width: 'fit-content',
                                fontSize: '20px',
                                cursor:
                                    popupContent.text === 'Revealing Soon'
                                        ? 'not-allowed'
                                        : 'pointer',
                                opacity:
                                    popupContent.text === 'Revealing Soon'
                                        ? 0.5
                                        : 1,
                            }}
                            onClick={() => {
                                if (popupContent.text !== 'Revealing Soon') {
                                    window.location.href = popupContent.url; // Navigate to the URL
                                }
                            }}
                        >
                            REGISTER
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Tube;
