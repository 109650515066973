import React, { useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './About.css';
const Comm = [
    {
        id: 1,
        icon: require('../../assets/scoms-2025/YeshviC.jpg'),
        title: 'Yeshvi Chandiramani',
        content: 'Chairperson',
    },
    {
        id: 2,
        icon: require('../../assets/scoms-2025/AdvaitNair.jpg'),
        title: 'Advait Nair',
        content: 'Vice Chairperson',
    },
    {
        id: 3,
        icon: require('../../assets/scoms-2025/AayushiB.jpg'),
        title: 'Aayushi Batham',
        content: 'Secretary',
    },
    {
        id: 4,
        icon: require('../../assets/scoms-2025/AnisaN.jpg'),
        title: 'Anisa Nathani',
        content: 'Joint Secretary',
    },
    {
        id: 5,
        icon: require('../../assets/scoms-2025/SahilM.jpg'),
        title: 'Sahil Mangtani',
        content: 'Treasurer',
    },
    {
        id: 6,
        icon: require('../../assets/scoms-2025/TarunK.jpg'),
        title: 'Tarun Kartha',
        content: 'Joint Treasurer & Logistics Head',
    },
    {
        id: 7,
        icon: require('../../assets/scoms-2025/SantushtM.jpg'),
        title: 'Santusht Matra',
        content: 'Technical Head',
    },
    {
        id: 8,
        icon: require('../../assets/scoms-2025/DurveshG.jpg'),
        title: 'Durvesh Gawde',
        content: 'Marketing Head',
    },
    {
        id: 9,
        icon: require('../../assets/scoms-2025/TanishR.jpg'),
        title: 'Tanish Raigandhi',
        content: 'Design Head',
    },
    {
        id: 10,
        icon: require('../../assets/scoms-2025/PrithviBirbale.jpg'),
        title: 'Prithvi Birbale',
        content: 'Creatives Head',
    },
    {
        id: 11,
        icon: require('../../assets/scoms-2025/AaryanK.jpg'),
        title: 'Aaryan Khan',
        content: 'SMM Head',
    },
    {
        id: 12,
        icon: require('../../assets/scoms-2025/KhushiNayak.jpg'),
        title: 'Khushi Nayak',
        content: 'Human Resource Manager',
    },
    {
        id: 13,
        icon: require('../../assets/scoms-2025/DhawalA.jpg'),
        title: 'Dhawal Abhonkar',
        content: 'Publicity Head',
    },
    {
        id: 14,
        icon: require('../../assets/scoms-2025/JatinN.jpg'),
        title: 'Jatin Nimje',
        content: 'Content Head',
    },
    {
        id: 15,
        icon: require('../../assets/scoms-2025/MdShafiq.jpg'),
        title: 'Mohammed Shafiq',
        content: 'Event Head',
    },
];

const data = [
    {
        question: 'What is the objective of IETE?',
        answer: 'IETE conducts workshops and seminars with the aim of facilitating the exchange of information and ideas among students with the growth of the technical industry and provides continuing education as well as career advancement opportunities to its members.',
    },
    {
        question: 'What are IETE’s major events?',
        answer: 'Our two flagship events are Oscillation and Elan. Oscillation is an even-semester technical event packed with enlightening workshops that would help you hone your skills and exciting non-technical events all lined up for you! Just like its name, Elan is an extravagant odd-semester tech fest filled with the perfect combination of energy, style, and enthusiasm for technical and non-technical events.',
    },
    {
        question: 'How to register for the events?',
        answer: 'The registration links for our events would be available on our website and in our Instagram bio.',
    },
    {
        question: 'How to be a part of IETE-TSEC?',
        answer: 'IETE TSEC opens its Senior Committee (SCOMs) applications for third-year students (TEs). The Junior Committee (JCOMs) applications are open only for second-year students (SEs) .The application links for the same would be available on the website.',
    },
    {
        question: 'What are the various positions offered?',
        answer: ' IETE TSEC consists of 7 departments: Publicity, Marketing, Creatives, Design, Technical, Social Media, and Logistics.',
    },
];

const TiltCard = ({ data }) => {
    const { icon, title, content } = data;

    const variants = {
        front: { rotateY: 0, zIndex: 1 },
        back: { rotateY: 180, zIndex: 0 },
    };

    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="relative w-64 h-64 perspective-md"
        >
            <motion.div
                className="absolute w-full h-full"
                style={{ transformStyle: 'preserve-3d' }}
                variants={variants}
                animate={isHovered ? 'back' : 'front'}
                transition={{ duration: 0.5 }}
            >
                <motion.div
                    className="absolute w-full h-full bg-card-gradient rounded-lg shadow-lg flex items-center justify-center"
                    style={{ backfaceVisibility: 'hidden' }}
                >
                    <img
                        src={icon}
                        alt={title}
                        className="w-48 h-48 object-cover rounded-full"
                    />
                </motion.div>

                <motion.div
                    className="absolute w-full h-full bg-card-gradient rounded-lg shadow-lg flex items-center justify-center flex-col text-white"
                    style={{ backfaceVisibility: 'hidden', rotateY: 180 }}
                >
                    <h2 className="text-2xl font-bold px-1">{title}</h2>
                    <p className="text-lg text-white px-2 text-center">
                        {content}
                    </p>
                </motion.div>
            </motion.div>
        </div>
    );
};

const About = () => {
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const controls = useAnimation();
    const { ref, inView } = useInView();

    const handleQuestionClick = (index) => {
        setSelectedQuestion(index === selectedQuestion ? null : index);
    };

    React.useEffect(() => {
        if (inView) {
            controls.start({
                opacity: 1,
                y: 0,
                transition: { duration: 0.5, ease: 'linear' },
            });
        }
    }, [controls, inView]);
    const faqItemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: (i) => ({
            opacity: 1,
            y: 0,
            transition: { delay: i * 0.2, duration: 0.5 }, // Each item will have a delay based on its index
        }),
    };

    return (
        <>
            <div className="w-full h-[60vh] flex items-center justify-center bg-primary">
                <div className="absolute top-[50px] justify-center inline-flex flex-row inset-0">
                    <div className="bg-light opacity-60 w-[400px] h-[100px] rounded-full  overflow-hidden relative filter blur-[80px] animate-gradientMesh_1"></div>
                    <div className="bg-light2 opacity-60 w-[300px] h-[100px] rounded-full overflow-hidden relative filter blur-[80px] animate-gradientMesh_2"></div>
                    <div className="bg-light opacity-60 w-[400px] h-[100px] rounded-full overflow-hidden relative filter blur-[80px] animate-gradientMesh_1"></div>
                </div>
                <motion.div
                    className=" text-white w-full"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { duration: 0, delay: 0 } }}
                    transition={{ delay: 0, duration: 2 }}
                >
                    <motion.h1 className="text-6xl lg:text-8xl mt-16 text-primary font-extrabold mb-2  text-center transition-all ease-in">
                        Our Committee
                    </motion.h1>
                </motion.div>
            </div>
            <div className="min-h-screen bg-primary p-8 md:p-12 lg:p-16 xl:p-20">
                <div className="flex items-center justify-center py-12">
                    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-3 gap-10 md:gap-20">
                        {Comm.map((data, index) => (
                            <div key={index} className="mt-4">
                                <TiltCard data={data} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className="py-12 bg-primary">
                    <div
                        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
                        ref={ref}
                    >
                        <div className="text-center">
                            <h2 className="text-4xl font-extrabold text-white sm:text-4xl mt-8">
                                Frequently Asked Questions
                            </h2>
                        </div>
                        <div className="mt-12">
                            {data.map((item, i) => (
                                <motion.div
                                    key={i}
                                    custom={i} // Pass the index as custom prop for staggered animation
                                    variants={faqItemVariants}
                                    initial="hidden"
                                    animate={inView ? 'visible' : 'hidden'} // Animate based on inView state
                                    className="mb-8"
                                >
                                    <h3
                                        onClick={() => handleQuestionClick(i)}
                                        className={`text-xl font-medium cursor-pointer ${
                                            selectedQuestion === i
                                                ? 'text-teal-500'
                                                : 'text-white'
                                        }`}
                                    >
                                        {item.question}
                                        <span className="float-right">+</span>
                                    </h3>
                                    {selectedQuestion === i && (
                                        <p className="mt-2 text-base text-teal-500">
                                            {item.answer}
                                        </p>
                                    )}
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;
