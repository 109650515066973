import React from "react";
import { Film, Star } from "lucide-react";

const Closed = ({title}) => {
   return (
      <div className="main font-mono w-full flex flex-col items-center mx-0 my-auto bg-cover bg-center bg-no-repeat bg-fixed overflow-hidden">
         <div
            className="w-full h-screen md:bg-center md:bg-no-repeat md:bg-cover items-center align-middle relative"
            id="regContainer"
         >
            {/* Floating Film Icons */}
            <div className="absolute w-full h-full overflow-hidden pointer-events-none">
               {[...Array(5)].map((_, i) => (
                  <Film
                     key={`film-${i}`}
                     className={`absolute text-white/20 animate-float-${i}`}
                     size={48}
                     style={{
                        top: `${Math.random() * 100}%`,
                        left: `${Math.random() * 100}%`,
                        animationDelay: `${i * 1.5}s`,
                     }}
                  />
               ))}
            </div>

            {/* Film Strip Border Top */}
            <div className="absolute top-0 w-full h-12 bg-black flex overflow-hidden">
               {[...Array(12)].map((_, i) => (
                  <div
                     key={`strip-top-${i}`}
                     className="flex-shrink-0 w-12 h-full border-r-2 border-white/20 bg-black"
                  >
                     <div className="w-8 h-8 bg-white/20 m-2"></div>
                  </div>
               ))}
            </div>

            {/* Main Content Container */}
            <div className="flex flex-col items-center justify-center h-full">
               {/* Title with Stars */}
               <div className="flex items-center gap-4 mb-8">
                  <Star
                     className="text-yellow-400 animate-spin-slow"
                     size={32}
                  />
                  <h1 className="text-4xl md:text-7xl text-white font-extrabold splFont tracking-wider">
                     {title}
                  </h1>
                  <Star
                     className="text-yellow-400 animate-spin-slow"
                     size={32}
                  />
               </div>

               {/* Main Message Box with Spotlight Effect */}
               <div className="relative bg-black bg-opacity-70 backdrop-blur-sm p-8 rounded-lg border-2 border-white max-w-2xl mx-4 shadow-2xl">
                  {/* Spotlight Gradient */}
                  <div className="absolute -inset-4 bg-gradient-to-r from-transparent via-white/5 to-transparent animate-spotlight"></div>

                  <h2 className="text-3xl md:text-5xl text-red-600 font-bold mb-4 text-center animate-pulse">
                     Registration Closed
                  </h2>
                  <p className="text-white text-xl md:text-2xl text-center mb-4">
                     Thank you for your interest in {title}
                  </p>
                  <p className="text-gray-300 text-lg md:text-xl text-center">
                     Stay tuned for future updates!
                  </p>
               </div>
            </div>

            {/* Film Strip Border Bottom */}
            <div className="absolute bottom-0 w-full h-12 bg-black flex overflow-hidden">
               {[...Array(12)].map((_, i) => (
                  <div
                     key={`strip-bottom-${i}`}
                     className="flex-shrink-0 w-12 h-full border-r-2 border-white/20 bg-black"
                  >
                     <div className="w-8 h-8 bg-white/20 m-2"></div>
                  </div>
               ))}
            </div>
         </div>

         <style jsx>{`
            @keyframes float {
               0%,
               100% {
                  transform: translateY(0) rotate(0deg);
               }
               50% {
                  transform: translateY(-20px) rotate(180deg);
               }
            }
            @keyframes spotlight {
               0%,
               100% {
                  opacity: 0;
                  transform: translateX(-100%);
               }
               50% {
                  opacity: 1;
                  transform: translateX(100%);
               }
            }
            @keyframes spin-slow {
               from {
                  transform: rotate(0deg);
               }
               to {
                  transform: rotate(360deg);
               }
            }
            .animate-float-0 {
               animation: float 6s ease-in-out infinite;
            }
            .animate-float-1 {
               animation: float 7s ease-in-out infinite;
            }
            .animate-float-2 {
               animation: float 8s ease-in-out infinite;
            }
            .animate-float-3 {
               animation: float 9s ease-in-out infinite;
            }
            .animate-float-4 {
               animation: float 10s ease-in-out infinite;
            }
            .animate-spotlight {
               animation: spotlight 3s linear infinite;
            }
            .animate-spin-slow {
               animation: spin-slow 4s linear infinite;
            }
         `}</style>
      </div>
   );
};

export default Closed;
