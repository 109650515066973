import React from 'react';
import './Newcss.css';
function NewbieLoader() {
    return (
        <div className="flex justify-center items-center -mt-5 md:ml-0 -ml-28 md:mr-16 mr-24 md:mb-32 mb-10 NBody">
            <div className="mainNewbieLoader">
                <svg
                    class="hourglass"
                    viewBox="0 0 56 56"
                    width="56px"
                    height="56px"
                    role="img"
                    aria-label="Hourglass being flipped clockwise and circled by three white curves fading in and out"
                >
                    <clipPath id="sand-mound-top">
                        <path
                            class="hourglass__sand-mound-top"
                            d="M 14.613 13.087 C 15.814 12.059 19.3 8.039 20.3 6.539 C 21.5 4.789 21.5 2.039 21.5 2.039 L 3 2.039 C 3 2.039 3 4.789 4.2 6.539 C 5.2 8.039 8.686 12.059 9.887 13.087 C 11 14.039 12.25 14.039 12.25 14.039 C 12.25 14.039 13.5 14.039 14.613 13.087 Z"
                        />
                    </clipPath>
                    <clipPath id="sand-mound-bottom">
                        <path
                            class="hourglass__sand-mound-bottom"
                            d="M 14.613 20.452 C 15.814 21.48 19.3 25.5 20.3 27 C 21.5 28.75 21.5 31.5 21.5 31.5 L 3 31.5 C 3 31.5 3 28.75 4.2 27 C 5.2 25.5 8.686 21.48 9.887 20.452 C 11 19.5 12.25 19.5 12.25 19.5 C 12.25 19.5 13.5 19.5 14.613 20.452 Z"
                        />
                    </clipPath>
                    <g transform="translate(2,2)">
                        <g
                            fill="none"
                            stroke="hsl(0,0%,100%)"
                            stroke-dasharray="153.94 153.94"
                            stroke-dashoffset="153.94"
                            stroke-linecap="round"
                            transform="rotate(-90,26,26)"
                        >
                            <circle
                                class="hourglass__motion-thick"
                                stroke-width="2.5"
                                cx="26"
                                cy="26"
                                r="24.5"
                                transform="rotate(0,26,26)"
                            />
                            <circle
                                class="hourglass__motion-medium"
                                stroke-width="1.75"
                                cx="26"
                                cy="26"
                                r="24.5"
                                transform="rotate(90,26,26)"
                            />
                            <circle
                                class="hourglass__motion-thin"
                                stroke-width="1"
                                cx="26"
                                cy="26"
                                r="24.5"
                                transform="rotate(180,26,26)"
                            />
                        </g>
                        <g
                            class="hourglass__model"
                            transform="translate(13.75,9.25)"
                        >
                            <path
                                fill="hsl(var(--hue),90%,85%)"
                                d="M 1.5 2 L 23 2 C 23 2 22.5 8.5 19 12 C 16 15.5 13.5 13.5 13.5 16.75 C 13.5 20 16 18 19 21.5 C 22.5 25 23 31.5 23 31.5 L 1.5 31.5 C 1.5 31.5 2 25 5.5 21.5 C 8.5 18 11 20 11 16.75 C 11 13.5 8.5 15.5 5.5 12 C 2 8.5 1.5 2 1.5 2 Z"
                            />
                            <g stroke="hsl(35,90%,90%)" stroke-linecap="round">
                                <line
                                    class="hourglass__sand-grain-left"
                                    stroke-width="1"
                                    stroke-dasharray="0.25 33.75"
                                    x1="12"
                                    y1="15.75"
                                    x2="12"
                                    y2="20.75"
                                />
                                <line
                                    class="hourglass__sand-grain-right"
                                    stroke-width="1"
                                    stroke-dasharray="0.25 33.75"
                                    x1="12.5"
                                    y1="16.75"
                                    x2="12.5"
                                    y2="21.75"
                                />
                                <line
                                    class="hourglass__sand-drop"
                                    stroke-width="1"
                                    stroke-dasharray="0.5 107.5"
                                    x1="12.25"
                                    y1="18"
                                    x2="12.25"
                                    y2="31.5"
                                />
                                <line
                                    class="hourglass__sand-fill"
                                    stroke-width="1.5"
                                    stroke-dasharray="54 54"
                                    x1="12.25"
                                    y1="14.75"
                                    x2="12.25"
                                    y2="31.5"
                                />
                                <line
                                    class="hourglass__sand-line-left"
                                    stroke="hsl(35,90%,83%)"
                                    stroke-width="1"
                                    stroke-dasharray="1 107"
                                    x1="12"
                                    y1="16"
                                    x2="12"
                                    y2="31.5"
                                />
                                <line
                                    class="hourglass__sand-line-right"
                                    stroke="hsl(35,90%,83%)"
                                    stroke-width="1"
                                    stroke-dasharray="12 96"
                                    x1="12.5"
                                    y1="16"
                                    x2="12.5"
                                    y2="31.5"
                                />
                                <g fill="hsl(35,90%,90%)" stroke-width="0">
                                    <path
                                        clip-path="url(#sand-mound-top)"
                                        d="M 12.25 15 L 15.392 13.486 C 21.737 11.168 22.5 2 22.5 2 L 2 2.013 C 2 2.013 2.753 11.046 9.009 13.438 L 12.25 15 Z"
                                    />
                                    <path
                                        clip-path="url(#sand-mound-bottom)"
                                        d="M 12.25 18.5 L 15.392 20.014 C 21.737 22.332 22.5 31.5 22.5 31.5 L 2 31.487 C 2 31.487 2.753 22.454 9.009 20.062 Z"
                                    />
                                </g>
                            </g>
                            <g
                                fill="none"
                                opacity="0.7"
                                stroke-linecap="round"
                                stroke-width="2"
                            >
                                <path
                                    class="hourglass__glare-top"
                                    stroke="hsl(0,0%,100%)"
                                    d="M 19.437 3.421 C 19.437 3.421 19.671 6.454 17.914 8.846 C 16.157 11.238 14.5 11.5 14.5 11.5"
                                />
                                <path
                                    class="hourglass__glare-bottom"
                                    stroke="hsla(0,0%,100%,0)"
                                    d="M 19.437 3.421 C 19.437 3.421 19.671 6.454 17.914 8.846 C 16.157 11.238 14.5 11.5 14.5 11.5"
                                    transform="rotate(180,12.25,16.75)"
                                />
                            </g>
                            <rect
                                fill="hsl(var(--hue),90%,50%)"
                                width="24.5"
                                height="2"
                            />
                            <rect
                                fill="hsl(var(--hue),90%,57.5%)"
                                rx="0.5"
                                ry="0.5"
                                x="2.5"
                                y="0.5"
                                width="19.5"
                                height="1"
                            />
                            <rect
                                fill="hsl(var(--hue),90%,50%)"
                                y="31.5"
                                width="24.5"
                                height="2"
                            />
                            <rect
                                fill="hsl(var(--hue),90%,57.5%)"
                                rx="0.5"
                                ry="0.5"
                                x="2.5"
                                y="32"
                                width="19.5"
                                height="1"
                            />
                        </g>
                    </g>
                </svg>
            </div>
        </div>
    );
}

export default NewbieLoader;
