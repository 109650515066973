import React from 'react';
import '../../App.css';
import SponsorsBg from '../../assets/sponsors/SponsorsBg.png';

import Axure from '../../assets/sponsors/osci-24/axure.png';
import Boombird from '../../assets/sponsors/osci-24/boombird.jpg';
import GFG from '../../assets/sponsors/osci-24/geeksforgeeks.jpg';
import GrowthSchool from '../../assets/sponsors/osci-24/growthschool.png';
import Hell from '../../assets/sponsors/osci-24/Hell.jpeg';
import NoEscape from '../../assets/sponsors/osci-24/noescape.jpg';
import Veraku from '../../assets/sponsors/osci-24/veraku.jpg';
import InterviewBuddy from '../../assets/sponsors/osci-24/interviewbuddy.JPG';

const imageUrls = [
  Axure,
  Boombird,
  GFG,
  GrowthSchool,
  Hell,
  InterviewBuddy,
  NoEscape,
  Veraku,
];

const slideWidth = 200;
const numberOfSlides = 16;

const FilmRollSlide = ({ imageSrc, index }) => {
  return (
    <div
      className="slide"
      style={{
        width: `${slideWidth}px`,
        height: `${slideWidth}px`,
        border: `${slideWidth / 20}px solid #000`,
        borderTopWidth: `${slideWidth / 5}px`,
        borderBottomWidth: `${slideWidth / 5}px`,
        backgroundColor: index % 2 === 0 ? '#777' : '#454545',
        position: 'relative',
        flexShrink: 0,
      }}
    >
      <img
        src={imageSrc}
        alt={`Slide ${index + 1}`}
        style={{
          width: '100%',
          height: '100%',
          objectFit: imageSrc === Axure ? 'contain' : 'cover',
          padding: 0,
        }}
      />
      {/* Top Notches */}
      <div
        className="notches top-notches"
        style={{
          position: 'absolute',
          width: '100%',
          height: `${slideWidth / 10}px`,
          left: 0,
          top: `${slideWidth - slideWidth * 1.15}px`,
          display: 'flex',
        }}
      >
        {[...Array(5)].map((_, j) => (
          <div
            key={`top-${j}`}
            className="notchesSub"
            style={{
              flex: 1,
              borderLeft: '10px solid #000',
              boxSizing: 'border-box',
              boxShadow: 'inset 5px 1px #444',
              backgroundColor: j === Math.floor(Math.random() * 6) ? '#efefef' : '#fff',
            }}
          />
        ))}
      </div>
      {/* Bottom Notches */}
      <div
        className="notches bottom-notches"
        style={{
          position: 'absolute',
          width: '100%',
          height: `${slideWidth / 10}px`,
          left: 0,
          bottom: `${slideWidth - slideWidth * 1.15}px`,
          display: 'flex',
        }}
      >
        {[...Array(5)].map((_, j) => (
          <div
            key={`bottom-${j}`}
            className="notchesSub"
            style={{
              flex: 1,
              borderLeft: '10px solid #000',
              boxSizing: 'border-box',
              boxShadow: 'inset 5px 1px #444',
              backgroundColor: j === Math.floor(Math.random() * 6) ? '#efefef' : '#fff',
            }}
          />
        ))}
      </div>
    </div>
  );
};

const slides = Array.from({ length: numberOfSlides }, (_, i) => (
  <FilmRollSlide key={i} index={i} imageSrc={imageUrls[i % imageUrls.length]} />
));

const Sponsors = () => {
  return (
    <div className="overflow-hidden">
      <div
        className="w-full px-4 py-2 text-white bg-primary bg-cover"
        style={{ backgroundImage: `url(${SponsorsBg})` }}
      >
        <h1 className="text-4xl normal-case text-center font-bold mt-4 mb-4 md:mb:0">
          Our Sponsors
        </h1>
        <p className="mb-8 mt-2 text-sm text-center opacity-70 hidden md:block md:px-40 normal-case">
          Throughout its illustrious journey, IETE has been embraced and uplifted by the unwavering support of numerous sponsors, each a beacon of commitment to technological advancement.
        </p>
      </div>
      <div className="slider-wrapper">
        <div className="slider-container">
          {slides}
          {slides /* Duplicate slides for seamless infinite scrolling */}
        </div>
      </div>
    </div>
  );
};

export default Sponsors;


// import Hell from "../../assets/sponsors/Hell.jpeg";
// import Holistik from "../../assets/sponsors/Holistik.jpeg";
// import Azorte from "../../assets/sponsors/Azorte.jpeg";
// import Habhit from "../../assets/sponsors/Habhit.jpeg";
// import Siuk from "../../assets/sponsors/Siuk.jpeg";
// import OffKicks from "../../assets/sponsors/OffKicks.jpeg";
// import SportFolio from "../../assets/sponsors/SportFolio.jpeg"


// const images = [
//    {
//       name: "Axure",
//       url: Axure,
//       className: "sm:h-24 sm:w-24 animate-anime0 ",
//    },
//    {
//       name: "Boombird",
//       url: Boombird,
//       className: "rounded-full h-16 w-16 animate-anime1",
//    },
//    {
//       name: "GeeksForGeeks",
//       url: GFG,
//       className: "rounded-full h-20 w-20 animate-anime2",
//    },
//    {
//       name: "GrowthSchool",
//       url: GrowthSchool,
//       className: "rounded-full h-22 w-22 animate-anime3",
//    },
//    {
//       name: "Hell",
//       url: Hell,
//       className: "rounded-full h-18 w-18 animate-anime4",
//    },
//    {
//       name: "NoEscape",
//       url: NoEscape,
//       className: "rounded-full h-24 w-24 animate-anime5",
//    },
//    {
//       name: "Veraku",
//       url: Veraku,
//       className: "rounded-full h-24 w-24 animate-anime6",
//    },
//    {
//       name: "Interview Buddy",
//       url: InterviewBuddy,
//       className: "rounded-full h-24 w-24 animate-anime6",
//    },
// ];
// style={{ backgroundImage: url(${SponsorsBg}) }}
{/* <div className="animate-infinite-scroll flex duration-100 " */}
   // Added index for unique keys
   {/* Added duration-300 class */}
{/* </div> */}
{/* <div className="animate-infinite-scroll flex duration-100 " */}
   {/* Added duration-300 class */}
{/* </div> */}
{/* <div className="animate-infinite-scroll speed flex duration-100 " */}
   {/* Added duration-300 class */}
{/* </div> */}
