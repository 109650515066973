import React from 'react';
import NowShowing from './NowShowing';
import eventBanner from './assets/oscillation.png';
import './Spotlight.css';
import { useNavigate } from 'react-router-dom';

const Spotlight = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/oscillation/tower');
    };

    return (
        <div className="mainSpotlight h-screen">
            <div className="wrapper">
                <div className="backdrop"></div>
                <div className="stage_floor"></div>
                <div className="stage_highlight"></div>
                <div className="spotlight_swivel">
                    <div className="lamp"></div>
                    <div className="spotlight"></div>
                </div>
                <div className="spotlight-text relative w-full flex justify-center flex-col md:px-0 px-2 py-4 h-[30vh] md:mt-0 mt-24 md:h-fit">
                    <div>
                        <div className="border-circles md:w-fit md:mx-auto">
                            <NowShowing />
                            <img
                                src={eventBanner}
                                alt="Event Banner"
                                className="spotlight-image md:h-auto md:w-8/12 ml-4 md:mx-auto"
                            />
                        </div>
                        <span>
                            <button
                                className="inline-block mt-10 px-6 py-3 text-base font-bold text-white bg-gradient-to-br from-red-600 to-yellow-400 border-none rounded-lg cursor-pointer uppercase shadow-lg transition-all ease-in-out duration-300 hover:bg-gradient-to-br hover:from-yellow-400 hover:to-red-600 hover:scale-105 hover:shadow-[0_0_20px_rgba(255,_204,_0,_0.9)]"
                                onClick={handleClick}
                            >
                                Register
                            </button>
                        </span>
                    </div>
                </div>
            </div>

            <style jsx>{`
                .border-circles {
                    position: relative;
                    padding: 1rem;
                }

                .border-circles::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-image: repeating-linear-gradient(
                            90deg,
                            yellow 0,
                            yellow 4px,
                            transparent 4px,
                            transparent 15px
                        ),
                        repeating-linear-gradient(
                            90deg,
                            yellow 0,
                            yellow 4px,
                            transparent 4px,
                            transparent 15px
                        ),
                        repeating-linear-gradient(
                            0deg,
                            yellow 0,
                            yellow 4px,
                            transparent 4px,
                            transparent 15px
                        ),
                        repeating-linear-gradient(
                            0deg,
                            yellow 0,
                            yellow 4px,
                            transparent 4px,
                            transparent 15px
                        );
                    background-position: top, bottom, left, right;
                    background-size: 100% 4px, 100% 4px, 4px 100%, 4px 100%;
                    background-repeat: no-repeat;
                    animation: borderBlink 1s ease-in-out infinite alternate;
                    pointer-events: none;
                }

                @keyframes borderBlink {
                    from {
                        opacity: 1;
                        filter: hue-rotate(0deg);
                    }
                    to {
                        opacity: 0.5;
                        filter: hue-rotate(60deg) brightness(2);
                    }
                }
            `}</style>
        </div>
    );
};

export default Spotlight;
