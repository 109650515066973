import React, { useState, useEffect } from 'react';
import './Clock.css';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const CountdownTimer = () => {
    const targetDate = new Date('2025-02-28T00:00:00');
    const initialCountdown = Math.floor((targetDate - new Date()) / 1000);

    const [countdown, setCountdown] = useState(initialCountdown);
    const [loading, setLoading] = useState(false);
    const [ref, inView] = useInView({ triggerOnce: true });

    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const startLoadingAnimation = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    useEffect(() => {
        if (inView) {
            startLoadingAnimation();
        }
    }, [inView]);

    const formatTime = (seconds) => {
        const days = Math.floor(seconds / (24 * 3600));
        const hours = Math.floor((seconds % (24 * 3600)) / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        return {
            days,
            hours,
            minutes,
            seconds: remainingSeconds,
        };
    };

    return (
        <div
            ref={ref}
            className="flex items-center justify-center mt-40 mb-40 md:mb-0 md:mt-0 h-screen flex-col"
        >
            <h2 className="text-2xl md:text-6xl font-bold mb-4 text-white">
                Days Until OSCILLATION
            </h2>
            <div className="block md:flex">
                {Object.entries(formatTime(countdown)).map(
                    ([unit, value], index) => (
                        <motion.div
                            key={unit}
                            className="text-center mx-4 relative"
                            initial={{ opacity: 0, scale: 0.5 }}
                            animate={{
                                opacity: 1,
                                scale: loading ? 1.25 : 1,
                                rotate: loading
                                    ? 720 * (index + 0.5)
                                    : 360 * index,
                            }}
                            exit={{ opacity: 0, scale: 0.5 }}
                            transition={{ type: 'spring', stiffness: 50 }}
                        >
                            <svg
                                className="circular-chart w-36 h-36 md:w-52 m-8"
                                viewBox="0 0 120 120"
                            >
                                {Array.from({ length: 60 }).map((_, index) => {
                                    const angle = (360 / 60) * index - 90;
                                    return (
                                        <circle
                                            key={index}
                                            cx={
                                                60 +
                                                50 *
                                                    Math.cos(
                                                        (angle * Math.PI) / 180
                                                    )
                                            }
                                            cy={
                                                60 +
                                                50 *
                                                    Math.sin(
                                                        (angle * Math.PI) / 180
                                                    )
                                            }
                                            r="2"
                                            fill={
                                                index < (value / 60) * 60
                                                    ? '#1abc9c'
                                                    : '#fff'
                                            }
                                            style={{
                                                opacity:
                                                    index < (value / 60) * 60
                                                        ? 1
                                                        : 0.5,
                                            }}
                                        />
                                    );
                                })}
                                <text
                                    x="50%"
                                    y="50%"
                                    className="percentage text-white font-bold"
                                    dominantBaseline="central"
                                    textAnchor="middle"
                                >
                                    {value}
                                </text>
                            </svg>
                            <div className="text-xs mt-2 text-white">
                                {unit}
                            </div>
                        </motion.div>
                    )
                )}
            </div>
        </div>
    );
};

export default CountdownTimer;
