import { useState, useEffect } from 'react';

const NeonText = () => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <div className="h-fit bg-transparent flex items-center justify-center p-4">
            <h1 className="flex flex-wrap justify-center text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-light tracking-tighter">
                <span className="flex gap-1 sm:gap-2 md:text-5xl text-3xl">
                    <span
                        className="text-cyan-50 animate-pulse"
                        style={{ textShadow: '0 0 2rem #00f0ff' }}
                    >
                        N
                    </span>
                    <span
                        className="text-cyan-50 animate-pulse"
                        style={{ textShadow: '0 0 2rem #00f0ff' }}
                    >
                        O
                    </span>
                    <span
                        className="text-cyan-50 animate-pulse"
                        style={{ textShadow: '0 0 2rem #00f0ff' }}
                    >
                        W
                    </span>
                </span>

                <span className="mx-4"></span>

                <span className="flex gap-1 sm:gap-2 md:text-5xl text-3xl">
                    <span
                        className="text-cyan-50 animate-pulse"
                        style={{ textShadow: '0 0 2rem #00f0ff' }}
                    >
                        S
                    </span>
                    <span
                        className={`
            text-cyan-50 
            animate-[flicker_2s_ease-in-out_infinite_alternate]
            translate-y-4 -translate-x-1 rotate-12
          `}
                        style={{ textShadow: '0 0 2rem #00f0ff' }}
                    >
                        H
                    </span>
                    <span
                        className="text-cyan-50 animate-pulse"
                        style={{ textShadow: '0 0 2rem #00f0ff' }}
                    >
                        O
                    </span>
                    <span
                        className="text-cyan-50 animate-pulse"
                        style={{ textShadow: '0 0 2rem #00f0ff' }}
                    >
                        W
                    </span>
                    <span
                        className="text-cyan-50 animate-pulse"
                        style={{ textShadow: '0 0 2rem #00f0ff' }}
                    >
                        I
                    </span>
                    <span
                        className="text-cyan-50 animate-pulse"
                        style={{ textShadow: '0 0 2rem #00f0ff' }}
                    >
                        N
                    </span>
                    <span
                        className="text-cyan-50 animate-pulse"
                        style={{ textShadow: '0 0 2rem #00f0ff' }}
                    >
                        G
                    </span>
                </span>
            </h1>

            <style jsx>{`
                @keyframes flicker {
                    0%,
                    5%,
                    6.5% {
                        opacity: 1;
                        transform: translateY(1rem) translateX(-0.2rem)
                            rotate(10deg);
                    }
                    5.5%,
                    7% {
                        opacity: 0;
                        transform: translateY(1rem) translateX(-0.2rem)
                            rotate(10deg);
                    }
                    50% {
                        opacity: 1;
                        transform: translateY(1rem) translateX(-0.2rem)
                            rotate(13deg);
                    }
                    100% {
                        opacity: 1;
                        transform: translateY(1rem) translateX(-0.2rem)
                            rotate(10deg);
                    }
                }
            `}</style>
        </div>
    );
};

export default NeonText;
