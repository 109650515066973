import React, { useState, useEffect } from 'react';
import './StartingAnimation.css';
import Spotlight from './Spotlight';

const StartingAnimation = () => {
    // State to trigger the fade-out of the countdown and fade-in of Spotlight
    const [fadeCountdown, setFadeCountdown] = useState(false);
    const [showSpotlight, setShowSpotlight] = useState(false);

    useEffect(() => {
        // Polyfill for requestAnimationFrame and cancelAnimationFrame
        if (!window.requestAnimationFrame) {
            let nLastTime = 0,
                aVendors = ['moz', 'ms', 'o', 'webkit'];
            for (let i = 0; i < aVendors.length; ++i) {
                window.requestAnimationFrame =
                    window[aVendors[i] + 'RequestAnimationFrame'];
                window.cancelAnimationFrame =
                    window[aVendors[i] + 'CancelAnimationFrame'] ||
                    window[aVendors[i] + 'CancelRequestAnimationFrame'];
            }
            window.requestAnimationFrame = function (callback, element) {
                let nCurrentTime = new Date().getTime(),
                    nTimeToCall = Math.max(0, 16 - (nCurrentTime - nLastTime)),
                    nTimer = window.setTimeout(function () {
                        callback(nCurrentTime + nTimeToCall);
                    }, nTimeToCall);
                nLastTime = nCurrentTime + nTimeToCall;
                return nTimer;
            };
        }
        if (!window.cancelAnimationFrame) {
            window.cancelAnimationFrame = function (nTimer) {
                clearTimeout(nTimer);
            };
        }

        // Grab SVG elements
        const circleOuter = document.getElementById('circle-outer');
        const circleInner = document.getElementById('circle-inner');
        const lineH = document.getElementById('line-h');
        const lineV = document.getElementById('line-v');
        const numbers = document.getElementById('numbers');
        const nCircleCx = +circleOuter.getAttribute('cx');
        const nCircleCy = +circleOuter.getAttribute('cy');
        const nLineY = +lineH.getAttribute('y1');
        const nLineX = +lineV.getAttribute('x1');
        const nJitterLevel = 2.5;
        const startTime = Date.now();

        const jitter = () => {
            const animate = () => {
                const elapsed = Date.now() - startTime;
                const progress = elapsed / 2000; // 2 seconds total duration

                if (progress >= 1) {
                    // Reset positions
                    circleOuter.setAttribute('cx', nCircleCx);
                    circleInner.setAttribute('cx', nCircleCx);
                    circleOuter.setAttribute('cy', nCircleCy);
                    circleInner.setAttribute('cy', nCircleCy);
                    lineH.setAttribute('y1', nLineY);
                    lineH.setAttribute('y2', nLineY);
                    lineV.setAttribute('x1', nLineX);
                    lineV.setAttribute('x2', nLineX);
                    numbers.style.transform = '';

                    // Trigger fade-out animation for the countdown container
                    setFadeCountdown(true);
                    // After the fade-out duration (500ms here), show the Spotlight
                    setTimeout(() => {
                        setShowSpotlight(true);
                    }, 500);
                    return;
                }

                // Calculate jitter effect
                const nOffsetX =
                    Math.random() *
                    nJitterLevel *
                    (Math.random() >= 0.5 ? 1 : -1);
                const nOffsetY =
                    Math.random() *
                    nJitterLevel *
                    (Math.random() >= 0.5 ? 1 : -1);

                // Apply jitter transformations
                circleOuter.setAttribute('cx', nCircleCx + nOffsetX);
                circleInner.setAttribute('cx', nCircleCx + nOffsetX);
                circleOuter.setAttribute('cy', nCircleCy + nOffsetY);
                circleInner.setAttribute('cy', nCircleCy + nOffsetY);
                lineH.setAttribute('y1', nLineY + nOffsetY);
                lineH.setAttribute('y2', nLineY + nOffsetY);
                lineV.setAttribute('x1', nLineX + nOffsetX);
                lineV.setAttribute('x2', nLineX + nOffsetX);
                numbers.style.transform = `translate3d(${nOffsetX}px, ${nOffsetY}px, 0)`;

                requestAnimationFrame(animate);
            };

            requestAnimationFrame(animate);
        };

        // Start the jitter animation
        jitter();
    }, []);

    return (
        <div className="mainStartAnimation">
            <div className="starting-animation-wrapper">
                {/* Countdown animation container */}
                <div
                    id="countdown-container"
                    className={`countdown-container ${
                        fadeCountdown ? 'fade-out' : ''
                    }`}
                >
                    <div id="film-container">
                        <div className="animated-flicker">
                            <svg
                                id="film-mask"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xmlSpace="preserve"
                                width="100%"
                                height="100%"
                                preserveAspectRatio="none"
                                viewBox="0 0 400 225"
                            >
                                <rect
                                    className="rectangle01"
                                    width="400"
                                    height="225"
                                />
                                <line
                                    id="line-h"
                                    className="line01"
                                    x1="0"
                                    y1="112.5"
                                    x2="400"
                                    y2="112.5"
                                />
                                <line
                                    id="line-v"
                                    className="line02"
                                    x1="200"
                                    y1="0"
                                    x2="200"
                                    y2="225"
                                />
                            </svg>
                            <svg
                                id="film-countdown"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                xmlSpace="preserve"
                                width="100%"
                                height="100%"
                                viewBox="0 0 400 225"
                            >
                                <circle
                                    id="circle-outer"
                                    className="circle01"
                                    cx="200"
                                    cy="112.5"
                                    r="95"
                                />
                                <circle
                                    id="circle-inner"
                                    className="circle01"
                                    cx="200"
                                    cy="112.5"
                                    r="85"
                                />
                                <circle
                                    className="circle02 animated-rotate"
                                    cx="200"
                                    cy="112.5"
                                    r="494.5"
                                />
                                <g
                                    id="numbers"
                                    textAnchor="middle"
                                    className="no-select"
                                >
                                    <text id="animated-text1" x="200.5" y="155">
                                        3
                                    </text>
                                    <text id="animated-text2" x="200.5" y="155">
                                        2
                                    </text>
                                    <text id="animated-text3" x="200.5" y="155">
                                        1
                                    </text>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>

                {/* Spotlight container */}
                <div
                    id="spotlight-container"
                    className={`spotlight-container ${
                        showSpotlight ? 'fade-in' : ''
                    }`}
                >
                    {showSpotlight && <Spotlight />}
                </div>
            </div>
        </div>
    );
};

export default StartingAnimation;