import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Splash from './pages/Splash/Splash';
import ScrollToTop from './components/scroll/ScrollToTop';
import { Outlet, useLocation } from 'react-router-dom';
// import CursorTrails from './components/Cursor Trails/CursorTrails';
// import WandCursor from "./assets/wand.png";

function Layout() {
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useEffect(() => {
        const timer = setTimeout(() => setLoading(false), 4700);
        return () => clearTimeout(timer);
    }, []);

    const hideFooterPaths = ['/oscillation/tower'];
    const hideFooter = hideFooterPaths.includes(location.pathname);

    const layoutStyle = {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        // cursor: `url(${WandCursor}), auto`,
    };

    const contentStyle = {
        flex: 1,
    };

    return loading ? (
        <Splash />
    ) : (
        <div style={layoutStyle}>
            {/* <CursorTrails /> */}
            <ScrollToTop />
            <Navbar />
            <div style={contentStyle}>
                <Outlet />
            </div>
            {!hideFooter && <Footer />}
        </div>
    );
}

export default Layout;
