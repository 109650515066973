import React from 'react';
import StartingAnimation from '../../components/OscillationC/StartingAnimation';
import MurderMysteryLoader from '../../components/Loader/MurderMysteryLoader';
import NewbieLoader from '../../components/Loader/NewbieLoader';
import IplLoader from '../../components/Loader/IplLoader';
import Tower from '../../components/OscillationC/tower';

function Oscillation() {
    return (
        <>
            <StartingAnimation />
            {/* <MurderMysteryLoader /> */}
            {/* <NewbieLoader /> */}
            {/* <IplLoader /> */}
        </>
    );
}

export default Oscillation;
