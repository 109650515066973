import React, { useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import * as THREE from 'three';

const Tower = () => {
    const mountRef = useRef(null);
    const [hasLoaded, setHasLoaded] = useState(false); // New state to track page load

    useEffect(() => {
        toast('Scroll to Reveal !');

        // Scene setup
        const scene = new THREE.Scene();
        scene.background = new THREE.Color('#091528');

        // Camera setup
        const camera = new THREE.PerspectiveCamera(
            50,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
        );
        camera.position.set(0, 15, 35);
        camera.lookAt(0, 10, 0);

        // Renderer setup
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.shadowMap.enabled = true;
        if (mountRef.current) {
            mountRef.current.appendChild(renderer.domElement);
        }

        // Materials
        const woodMaterial = new THREE.MeshStandardMaterial({
            color: '#e1f1fd',
        });
        const stoneMaterial = new THREE.MeshStandardMaterial({
            color: '#f9c051',
        });
        const lightMaterial = new THREE.MeshStandardMaterial({
            color: '#901616',
            emissive: '#901616',
        });

        // Tower Structure
        const towerGroup = new THREE.Group();
        const baseGeometry = new THREE.CylinderGeometry(5, 5, 4, 32);
        const baseMesh = new THREE.Mesh(baseGeometry, stoneMaterial);
        baseMesh.position.y = 2;
        towerGroup.add(baseMesh);

        // Middle sections
        const heights = [6, 12, 17];
        const sizes = [3.5, 2.8, 2.5];
        heights.forEach((height, i) => {
            const geo = new THREE.CylinderGeometry(sizes[i], sizes[i], 6, 32);
            const mesh = new THREE.Mesh(geo, woodMaterial);
            mesh.position.y = height;
            towerGroup.add(mesh);
        });

        // Windows
        for (let i = 0; i < 8; i++) {
            const windowGeo = new THREE.BoxGeometry(0.5, 1, 0.2);
            const windowMesh = new THREE.Mesh(windowGeo, lightMaterial);
            const angle = (i / 8) * Math.PI * 2;
            windowMesh.position.set(
                Math.cos(angle) * 3,
                7 + (i % 3) * 6,
                Math.sin(angle) * 3
            );
            towerGroup.add(windowMesh);
        }

        // Event names using CanvasTexture
        const eventNames = ['Murder Mystery', 'IPL Auction', 'Newbiethon'];
        const createTextTexture = (text) => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = 512;
            canvas.height = 128;
            context.fillStyle = 'transparent';
            context.fillRect(0, 0, canvas.width, canvas.height);

            // Glow effect
            context.shadowColor = 'maroon';
            context.shadowBlur = 60;
            context.shadowOffsetX = 0;
            context.shadowOffsetY = 0;

            // Text style
            context.fillStyle = 'black';
            context.font = 'Bold 70px Arial';
            context.textAlign = 'center';
            context.fillText(text, canvas.width / 2, canvas.height / 2 + 15);

            return new THREE.CanvasTexture(canvas);
        };

        const textMeshes = [];
        heights.forEach((height, i) => {
            const textTexture = createTextTexture(eventNames[i]);
            const textMaterial = new THREE.MeshBasicMaterial({
                map: textTexture,
                transparent: true,
                opacity: 0,
                depthTest: i === 1 ? false : true,
                depthWrite: i === 1 ? false : true,
            });
            const textGeometry = new THREE.PlaneGeometry(6, 1.5);
            const textMesh = new THREE.Mesh(textGeometry, textMaterial);

            // For IPL Auction (index 1), push it further forward and set high renderOrder
            const zPos = i === 1 ? 7 : 5;
            textMesh.position.set(0, height, zPos);
            textMesh.renderOrder = i === 1 ? 1000 : 0;

            towerGroup.add(textMesh);
            textMeshes.push(textMesh);
        });

        // Cone at the top
        const coneGeometry = new THREE.ConeGeometry(3, 5, 32);
        const coneMesh = new THREE.Mesh(coneGeometry, stoneMaterial);
        coneMesh.position.y = 22;
        towerGroup.add(coneMesh);

        // Move the tower group down a bit
        towerGroup.position.y = -2;

        scene.add(towerGroup);

        // Lighting
        const ambientLight = new THREE.AmbientLight('#ffffff', 0.3);
        scene.add(ambientLight);
        const directionalLight = new THREE.DirectionalLight('#ffffff', 2);
        directionalLight.position.set(10, 20, 10);
        scene.add(directionalLight);

        // Raycaster for click detection
        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();
        const handleClick = (event) => {
            if (!hasLoaded) return; // Ignore clicks until the page has loaded

            const rect = renderer.domElement.getBoundingClientRect();
            mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
            raycaster.setFromCamera(mouse, camera);
            const intersects = raycaster.intersectObjects(textMeshes);
            if (intersects.length > 0) {
                const clickedMesh = intersects[0].object;
                if (clickedMesh === textMeshes[0]) {
                    window.location.href = '/oscillation/murdermystery';
                } else if (clickedMesh === textMeshes[1]) {
                    window.location.href = '/oscillation/iplauction';
                } else {
                    window.location.href = '/oscillation/newbiethon';
                }
            }
        };
        window.addEventListener('click', handleClick);

        // Scroll Animation
        const handleScroll = () => {
            const scrollY = window.scrollY;
            const documentHeight =
                document.documentElement.scrollHeight - window.innerHeight;
            const scrollFraction = Math.min(scrollY / documentHeight, 1);

            // Rotate tower based on scroll
            towerGroup.rotation.y = scrollFraction * 2 * Math.PI;

            // Control text visibility based on scroll thresholds
            const middleEventThreshold = 0.33;
            const endEventThreshold = 0.66;
            textMeshes.forEach((mesh, i) => {
                if (scrollFraction < middleEventThreshold) {
                    mesh.material.opacity = i === 0 ? 1 : 0;
                } else if (scrollFraction < endEventThreshold) {
                    mesh.material.opacity = i === 1 ? 1 : 0;
                } else {
                    mesh.material.opacity = i === 2 ? 1 : 0;
                }
            });

            // Uniform zoom
            camera.fov = THREE.MathUtils.lerp(50, 35, scrollFraction);
            camera.updateProjectionMatrix();
        };
        window.addEventListener('scroll', handleScroll);

        // Animation loop with text facing the camera
        const animate = () => {
            requestAnimationFrame(animate);
            textMeshes.forEach((mesh) => {
                mesh.lookAt(camera.position);
            });
            renderer.render(scene, camera);
        };
        animate();

        // Resize listener
        const handleResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };
        window.addEventListener('resize', handleResize);

        // Mark as loaded after a small delay
        setTimeout(() => {
            setHasLoaded(true); // Allow clicks after a delay
        }, 1000); // Delay to ensure page has fully loaded before allowing interaction

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('click', handleClick);
            if (renderer.domElement?.parentNode) {
                renderer.domElement.parentNode.removeChild(renderer.domElement);
            }
        };
    }, [hasLoaded]);

    return (
        <>
            <ToastContainer />
            <div>
                <div
                    ref={mountRef}
                    style={{
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed',
                        top: 0,
                        left: 0,
                    }}
                />
                <div
                    style={{
                        height: '300vh',
                        background:
                            'linear-gradient(to bottom, rgb(0, 0, 0), #141414)',
                    }}
                ></div>
            </div>
        </>
    );
};

export default Tower;
