import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
} from 'react-router-dom';

import Layout from './Layout';
import Home from './pages/Home/Home';
import Register from './pages/Register/Register-new';
import About from './pages/About/About';
import Events from './pages/Events/Events';
import Tube from './pages/Elan/Tube';
// import MurderMystery from './pages/Oscillation/murdermystery';
import ElanRender from './pages/Elan/ElanRender';
import Oscillation from './pages/Oscillation/Oscillation';
import Newbiethon from './pages/Oscillation/Newbiethon';
// import IplAuction from './pages/Oscillation/iplauction';
// import EventGallery from './components/EventGallery/EventGallery';
import Tower from './components/OscillationC/tower';
import Closed from './pages/Oscillation/Closed';
// import Login from './pages/IPLAuction/LoginTeam/Login';
// import Team from './pages/IPLAuction/Team/Team';
// import Elan from './pages/Elan/Elan';
// import AnimatedText from './components/Cypher/AnimatedText';
// import Howzat from './pages/Register/Howzat';
// import Workshop from './pages/Elan/Workshop';
// import Valorant from "./pages/Elan/Valorant";
// import TricksOfTrade from './pages/Elan/TricksOfTrade';
// import QrPage from './components/QrCodeComponents/QrPage';
// import MarkAttendance from './components/QrCodeComponents/MarkAttendance';
// import TreasureHunt from './pages/Elan/TreasureHunt';
// import Scoreboard from "./pages/Scoreboard/Scoreboard.jsx";
// import DetailedMatchScore from "./components/ScoreSlider/DetailedMatchScore.jsx";
// import ScoreSlider from "./components/ScoreSlider/ScoreSlider.jsx";
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="register" element={<Register />} />
            <Route path="about" element={<About />} />
            <Route path="events" element={<Events />} />
            <Route path="elan" element={<ElanRender />} />
            <Route path="elan/tube" element={<Tube />} />
            {/* <Route path="scoreboard" element={<Scoreboard />} /> */}
            {/* <Route path="elan/valorant" element={<Valorant />} /> */}
            {/* <Route path="elan/workshop" element={<Workshop />} /> */}
            {/* <Route path="elan/tricksoftrade" element={<TricksOfTrade />} /> */}
            <Route
                path="oscillation/iplauction"
                element={<Closed title="IPL Auction 3.0" />}
            />
            <Route
                path="oscillation/newbiethon"
                // element={<Closed title="Newbiethon 3.0" />}
                element={<Newbiethon />}
            />
            <Route
                path="oscillation/murdermystery"
                element={<Closed title="Lights, Camera, Murder" />}
            />
            <Route path="/oscillation" element={<Oscillation />} />
            <Route path="/oscillation/tower" element={<Tower />} />
            {/* <Route path="/iplauction/teamlogin" element={<Login />} /> */}
            {/* <Route path="/iplauction/team/:username" element={<Team />} /> */}
            {/* <Route path="/gallery" element={<EventGallery />} /> */}
            {/* <Route path="/cypher" element={<AnimatedText />} /> */}
            {/* <Route path="/teamQr/:teamName" element={<QrPage />} /> */}
            {/* <Route
                path="/elan/markattendance/:event"
                element={<MarkAttendance />}
            /> */}
            {/* <Route path="elan/treasurehunt" element={<TreasureHunt/>}/> */}
            {/* <Route path="score-slider" element={<ScoreSlider />} />
         <Route path="detail/:matchId" element={<DetailedMatchScore />} /> */}
        </Route>
    )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);
