import React, { useState, useEffect } from 'react';
import logo from '../../assets/iete-logo.png';
import { Link, NavLink, useLocation } from 'react-router-dom';

const Nav = () => {
    const [scrolling, setScrolling] = useState(false);
    const [hideNavbar, setHideNavbar] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                // setScrolling(true);
                setHideNavbar(true);
            } else {
                setHideNavbar(false);
                setScrolling(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    let Links = [
        { name: 'Home', link: '/' },
        { name: 'Events', link: '/Events' },
        // { name: "HOWZZAT", link: "/scoreboard" },
        // { name: 'ELAN', link: '/Elan' },
        { name: 'Oscillation', link: '/oscillation' },
        // { name: "Challenge", link: "/Challenge" },
        { name: 'About Us', link: '/About' },
    ];

    return (
        <>
            {!hideNavbar && (
                <div
                    className={`shadow-md px-4 w-full fixed top-0 left-0 z-50 transition-all bg-primary duration-500 ease-in ${
                        scrolling ? 'bg-primary py-4 md:px-10 px-7' : ''
                    }`}
                >
                    <div className="md:flex justify-between">
                        <Link to="/">
                            <div className="font-bold text-2xl cursor-pointer flex items-center font-[Poppins] text-white">
                                <span className="text-3xl text-indigo-600 mr-1 pt-2 pb-2">
                                    <img
                                        src={logo}
                                        className="mr-0.5 h-10 filter brightness-0 invert"
                                        alt="Logo"
                                    />
                                </span>
                                <span className="flex items-center pt-2">
                                    IETE TSEC
                                </span>
                            </div>
                        </Link>
                        <div
                            className="text-3xl absolute right-8 top-1/2 transform -translate-y-1/2 cursor-pointer md:hidden text-white"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 mb-2"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                    transform="translate(0, 6)"
                                />
                            </svg>
                        </div>
                        <ul
                            className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-primary md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in text-white ${
                                isOpen
                                    ? 'top-14 '
                                    : 'top-[-490px] flex items-center'
                            }`}
                        >
                            {Links.map((Link) => (
                                <li
                                    key={Link.name}
                                    className="md:ml-8 text-xl md:my-0 my-7"
                                >
                                    <NavLink
                                        to={Link.link}
                                        className={({ isActive }) =>
                                            `block py-2 pr-4 pl-3 duration-200 ${
                                                isActive
                                                    ? 'text-gray-100'
                                                    : 'text-gray-500'
                                            }  
                                        transition-all duration-2000 hover:underline
                                        hover:scale-110 hover:text-light
                                        lg:hover:bg-transparent lg:border-0  lg:p-0 `
                                        }
                                    >
                                        {Link.name}
                                    </NavLink>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </>
    );
};

export default Nav;
