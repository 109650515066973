import React, { useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Confetti from 'react-confetti';
import axios from 'axios';
import '../../App.css';
import NewbieLoader from '../../components/Loader/NewbieLoader';
import { motion, AnimatePresence } from 'framer-motion';

const Newbiethon = () => {
    const [step, setStep] = useState(1);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [hovered, setHovered] = useState(false);

    const nextStep = (step) => {
        setStep(step + 1);
    };

    const prevStep = (step) => {
        if (step == 1) {
            setStep(1);
        } else {
            setStep(step - 1);
        }
    };

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        reset,
    } = useForm({
        defaultValues: {
            teamName: '',
            leaderName: '',
            leaderYear: '',
            leaderBranch: '',
            leaderDivision: '',
            leaderRollNumber: '',
            leaderEmail: '',
            leaderPhone: '',
            players: Array(2)
                .fill()
                .map(() => ({
                    name: '',
                    year: '',
                    branch: '',
                    division: '',
                    rollNumber: '',
                })),
        },
    });

    const { fields: playerFields } = useFieldArray({
        control,
        name: 'players',
    });

    const onSubmit = async (data) => {
        setIsSubmitting(true);

        const formData = new FormData();
        formData.append('teamName', data.teamName);
        formData.append('leaderName', data.leaderName);
        formData.append('leaderYear', data.leaderYear);
        formData.append('leaderBranch', data.leaderBranch);
        formData.append('leaderDivision', data.leaderDivision);
        formData.append('leaderRollNumber', data.leaderRollNumber);
        formData.append('leaderEmail', data.leaderEmail);
        formData.append('leaderPhone', data.leaderPhone);
        formData.append('players', JSON.stringify(data.players));
        // console.log(data);

        try {
            await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/api/newbie`,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            toast.success('Registration Successful!');
            toast.success('Check you Mail Inbox for a surprise !');
            setIsSubmitted(true);
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
            setTimeout(() => {
                setIsSubmitted(false);
            }, 8000);
            reset();
        } catch (error) {
            toast.error('There was an error!');
            console.error('There was an error!', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <ToastContainer />
            <div className="main font-mono w-full flex flex-col items-center mx-0 my-auto bg-cover bg-center bg-no-repeat bg-fixed">
                <div
                    className="w-full h-full md:h-fit md:bg-center md:bg-no-repeat md:bg-cover items-center align-middle -mt-16"
                    id="regContainer"
                >
                    <div className="w-full text-center -mt-8 md:mt-32 font-extrabold">
                        <h1 className="whitespace-nowrap  md:whitespace-normal text-4xl text-white md:text-7xl mt-40 md:mt-32 ml-6 md:ml-0 splFont">
                            Newbiethon 3.0
                        </h1>
                    </div>
                    <div className="w-5/6 md:w-9/12 mt-5 mb-5 md:h-[100%] relative bg-white bg-opacity-10 backdrop-blur-xs border border-white border-opacity-30 rounded-2xl p-8 md:py-0 md:px-0 md:ml-56 md:mt-16 mx-auto">
                        {isSubmitting && (
                            <>
                                <div className="z-99999 bg-white bg-opacity-20 h-[80vh] w-full flex flex-col justify-center items-center">
                                    <NewbieLoader />
                                </div>
                            </>
                        )}
                        {!isSubmitting && (
                            <>
                                <form
                                    className="text-[1.5rem] text-black h-[600px] overflow-y-auto md:h-[80vh] md:ml-44 md:overflow-x-hidden form-scrollbar"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    {step === 1 && (
                                        <>
                                            <div className="form-group md:flex md:items-baseline md:justify-between md:mt-16 md:-mb-2 md:ml-36 mt-4 w-full text-left font-extrabold lg:items-baseline md:text-center ">
                                                <label
                                                    className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl font-[atma]"
                                                    htmlFor="teamName"
                                                >
                                                    Team Name
                                                </label>
                                                <div className="flex flex-col w-full items-start ">
                                                    <input
                                                        className="form-input max-w-full md:max-w-[50%] h-10 text-black w-full py-3 px-5 md:mx-8 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                        type="text"
                                                        id="teamName"
                                                        {...register(
                                                            'teamName',
                                                            {
                                                                required:
                                                                    'Team name is required',
                                                            }
                                                        )}
                                                    />
                                                    {errors.teamName && (
                                                        <div className="form-error text-red-500 mt-1 text-sm md:ml-8">
                                                            {
                                                                errors.teamName
                                                                    .message
                                                            }
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="mt-8">
                                                <h2 className="text-3xl text-black font-bold text-center whitespace-nowrap splFont">
                                                    Leader Details
                                                </h2>
                                                <div className="form-group md:flex md:items-baseline md:justify-between md:mt-10 md:ml-36 mt-4 w-full text-left font-extrabold lg:items-baseline md:text-center">
                                                    <label
                                                        className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl md:mr-2 font-[atma]"
                                                        htmlFor="leaderName"
                                                    >
                                                        Name
                                                    </label>
                                                    <div className="flex flex-col w-full items-start md:ml-24">
                                                        <input
                                                            className="form-input max-w-full md:max-w-[49%] h-10 text-black w-full py-3 px-5 md:mx-8 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                            type="text"
                                                            id="leaderName"
                                                            {...register(
                                                                'leaderName',
                                                                {
                                                                    required:
                                                                        'leader name is required',
                                                                }
                                                            )}
                                                        />
                                                        {errors.leaderName && (
                                                            <div className="form-error text-red-500 mt-1 md:ml-8 text-sm lg:ml-9">
                                                                {
                                                                    errors
                                                                        .leaderName
                                                                        .message
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group md:flex md:items-baseline md:justify-between md:mt-8 md:ml-36 mt-4 w-full text-left font-extrabold lg:items-baseline md:text-center">
                                                    <label
                                                        className="form-label whitespace-nowrap md:mb-5 md:mr-14 mr-8 text-lg md:text-3xl font-[atma]"
                                                        htmlFor="leaderYear"
                                                    >
                                                        Year
                                                    </label>
                                                    <div className="flex flex-col w-full items-start md:ml-24">
                                                        <select
                                                            className="form-input max-w-full md:max-w-[50%] h-11 text-black w-full py-3 px-5 md:mx-0 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                            id="leaderYear"
                                                            {...register(
                                                                'leaderYear',
                                                                {
                                                                    required:
                                                                        'leader year is required',
                                                                }
                                                            )}
                                                        >
                                                            <option
                                                                value=""
                                                                className="bh-white"
                                                            >
                                                                Select Year
                                                            </option>
                                                            <option
                                                                value="First Year"
                                                                className="bh-white"
                                                            >
                                                                FE
                                                            </option>
                                                        </select>
                                                        {errors.leaderYear && (
                                                            <div className="form-error text-red-500 mt-1 md:ml-12 text-sm lg:ml-1">
                                                                {
                                                                    errors
                                                                        .leaderYear
                                                                        .message
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group md:flex md:items-baseline md:justify-between md:mt-10 md:ml-36 mt-4 w-full text-left font-extrabold lg:items-baseline md:text-center">
                                                    <label
                                                        className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl md:mr-5 font-[atma]"
                                                        htmlFor="leaderBranch"
                                                    >
                                                        Branch
                                                    </label>
                                                    <div className="flex flex-col w-full items-start md:ml-24">
                                                        <select
                                                            className="form-input max-w-full md:max-w-[50%] h-11 text-black w-full py-3 px-5 md:mx-0 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                            id="leaderBranch"
                                                            {...register(
                                                                'leaderBranch',
                                                                {
                                                                    required:
                                                                        'leader branch is required',
                                                                }
                                                            )}
                                                        >
                                                            <option
                                                                value=""
                                                                className="bh-white"
                                                            >
                                                                Select Branch
                                                            </option>
                                                            <option
                                                                value="AIDS"
                                                                className="bh-white"
                                                            >
                                                                AIDS
                                                            </option>
                                                            <option
                                                                value="COMPS"
                                                                className="bh-white"
                                                            >
                                                                COMPS
                                                            </option>
                                                            <option
                                                                value="IT"
                                                                className="bh-white"
                                                            >
                                                                IT
                                                            </option>
                                                            <option
                                                                value="EXTC"
                                                                className="bh-white"
                                                            >
                                                                EXTC
                                                            </option>
                                                            <option
                                                                value="CHEM"
                                                                className="bh-white"
                                                            >
                                                                CHEM
                                                            </option>
                                                        </select>
                                                        {errors.leaderBranch && (
                                                            <div className="form-error text-red-500 mt-1 md:ml-3 text-sm lg:ml-2">
                                                                {
                                                                    errors
                                                                        .leaderBranch
                                                                        .message
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group md:flex md:items-baseline md:justify-between md:mt-10 md:ml-36 mt-4 w-full text-left font-extrabold lg:items-baseline md:text-center">
                                                    <label
                                                        className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl md:mr-2 font-[atma]"
                                                        htmlFor="leaderDivision"
                                                    >
                                                        Division
                                                    </label>
                                                    <div className="flex flex-col w-full items-start md:ml-16">
                                                        <input
                                                            className="form-input max-w-full md:max-w-[49%] h-10 text-black w-full py-3 px-5 md:mx-8 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                            type="text"
                                                            id="leaderDivision"
                                                            {...register(
                                                                'leaderDivision',
                                                                {
                                                                    required:
                                                                        'leader Division is required',
                                                                }
                                                            )}
                                                        />
                                                        {errors.leaderDivision && (
                                                            <div className="form-error text-red-500 mt-1 md:ml-8 text-sm lg:ml-9">
                                                                {
                                                                    errors
                                                                        .leaderDivision
                                                                        .message
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group md:flex md:items-baseline md:justify-between md:mt-10 md:ml-36 mt-4 w-full text-left font-extrabold lg:items-baseline md:text-center">
                                                    <label
                                                        className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl md:mr-2 font-[atma]"
                                                        htmlFor="leaderRollNumber"
                                                    >
                                                        Roll Number
                                                    </label>
                                                    <div className="flex flex-col w-full items-start ">
                                                        <input
                                                            className="form-input max-w-full md:max-w-[49%] h-10 text-black w-full py-3 px-5 md:mx-8 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                            type="number"
                                                            min="1"
                                                            id="leaderRollNumber"
                                                            {...register(
                                                                'leaderRollNumber',
                                                                {
                                                                    required:
                                                                        'leader Roll Number is required',
                                                                }
                                                            )}
                                                        />
                                                        {errors.leaderRollNumber && (
                                                            <div className="form-error text-red-500 mt-1 md:ml-8 text-sm lg:ml-9">
                                                                {
                                                                    errors
                                                                        .leaderRollNumber
                                                                        .message
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group md:flex md:items-baseline md:justify-between md:mt-10 md:ml-36 mt-4 w-full text-left font-extrabold lg:items-baseline md:text-center">
                                                    <label
                                                        className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl md:mr-10 font-[atma]"
                                                        htmlFor="leaderEmail"
                                                    >
                                                        Email
                                                    </label>
                                                    <div className="flex flex-col w-full items-start md:ml-24">
                                                        <input
                                                            className="form-input max-w-full md:max-w-[50%] h-11 text-black w-full py-3 px-5 md:mx-0 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                            type="email"
                                                            id="leaderEmail"
                                                            {...register(
                                                                'leaderEmail',
                                                                {
                                                                    required:
                                                                        'leader email is required',
                                                                    pattern: {
                                                                        value: /\S+@\S+\.\S+/,
                                                                        message:
                                                                            'Email address is invalid',
                                                                    },
                                                                }
                                                            )}
                                                        />
                                                        {errors.leaderEmail && (
                                                            <div className="form-error text-red-500 mt-1 md:ml-12 text-sm lg:ml-2">
                                                                {
                                                                    errors
                                                                        .leaderEmail
                                                                        .message
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="form-group md:flex md:items-baseline md:justify-between md:mt-10 md:ml-36 mt-4 w-full text-left font-extrabold lg:items-baseline md:text-center">
                                                    <label
                                                        className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl md:mr-8 font-[atma]"
                                                        htmlFor="leaderPhone"
                                                    >
                                                        Phone
                                                    </label>
                                                    <div className="flex flex-col w-full items-start md:ml-24">
                                                        <input
                                                            className="form-input max-w-full md:max-w-[50%] h-11 text-black w-full py-3 px-5 md:mx-0 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                            type="tel"
                                                            id="leaderPhone"
                                                            maxLength={10}
                                                            {...register(
                                                                'leaderPhone',
                                                                {
                                                                    required:
                                                                        'leader phone number is required',
                                                                }
                                                            )}
                                                        />
                                                        {errors.leaderPhone && (
                                                            <div className="form-error text-red-500 mt-1 md:ml-12 text-sm whitespace-nowrap lg:ml-2">
                                                                {
                                                                    errors
                                                                        .leaderPhone
                                                                        .message
                                                                }
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {step === 2 && (
                                        <div className="flex flex-col">
                                            {playerFields.length === 0 ? (
                                                <p>
                                                    No player fields available
                                                </p>
                                            ) : (
                                                playerFields.map(
                                                    (field, index) => (
                                                        <div className="">
                                                            <div
                                                                key={field.id}
                                                                className="form-group md:flex-col md:items-baseline md:justify-between md:mt-16 md:ml-36 mt-4 w-full text-left font-extrabold lg:items-baseline md:text-center gap-10"
                                                            >
                                                                <h2 className="font-[atma] text-start text-3xl mb-5 mt-10 whitespace-nowrap">
                                                                    Member{' '}
                                                                    {index + 1}{' '}
                                                                    Details
                                                                </h2>
                                                                <div className="flex md:flex-row flex-col md:items-baseline items-left">
                                                                    <label
                                                                        className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl md:mr-6 font-[atma]"
                                                                        htmlFor={`player${index}`}
                                                                    >
                                                                        Name
                                                                    </label>
                                                                    <div className="flex flex-col w-full items-start md:ml-20">
                                                                        <input
                                                                            className="form-input max-w-full md:max-w-[50%] h-11 text-black w-full py-3 px-5 md:mx-0 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                                            type="text"
                                                                            id={`player${index}`}
                                                                            {...register(
                                                                                `players.${index}.name`,
                                                                                {
                                                                                    required: `Player ${
                                                                                        index +
                                                                                        1
                                                                                    } name is required`,
                                                                                }
                                                                            )}
                                                                        />
                                                                        {errors
                                                                            .players?.[
                                                                            index
                                                                        ]
                                                                            ?.name && (
                                                                            <div className="form-error text-red-500 mt-1 md:ml-12 text-sm lg:ml-2">
                                                                                {
                                                                                    errors
                                                                                        .players[
                                                                                        index
                                                                                    ]
                                                                                        ?.name
                                                                                        .message
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="flex md:flex-row flex-col md:items-baseline items-left">
                                                                    <label
                                                                        className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl md:mr-6 font-[atma]"
                                                                        htmlFor={`player${index}Year`}
                                                                    >
                                                                        Year
                                                                    </label>
                                                                    <div className="flex flex-col w-full items-start md:ml-24">
                                                                        <select
                                                                            className="form-input max-w-full md:max-w-[50%] h-11 text-black w-full py-3 px-5 md:mx-0 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                                            id={`player${index}Year`}
                                                                            {...register(
                                                                                `players.${index}.year`,
                                                                                {
                                                                                    required: `Player ${
                                                                                        index +
                                                                                        1
                                                                                    } year is required`,
                                                                                }
                                                                            )}
                                                                        >
                                                                            <option
                                                                                value=""
                                                                                className="bh-white"
                                                                            >
                                                                                Select
                                                                                Year
                                                                            </option>
                                                                            <option
                                                                                value="First Year"
                                                                                className="bh-white"
                                                                            >
                                                                                FE
                                                                            </option>
                                                                        </select>
                                                                        {errors
                                                                            .players?.[
                                                                            index
                                                                        ]
                                                                            ?.year && (
                                                                            <div className="form-error text-red-500 mt-1 md:ml-12 text-sm lg:ml-2">
                                                                                {
                                                                                    errors
                                                                                        .players[
                                                                                        index
                                                                                    ]
                                                                                        ?.year
                                                                                        .message
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="flex md:flex-row flex-col md:items-baseline items-left">
                                                                    <label
                                                                        className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl md:mr-5 font-[atma]"
                                                                        htmlFor={`player${index}Branch`}
                                                                    >
                                                                        Branch
                                                                    </label>
                                                                    <div className="flex flex-col w-full items-start md:ml-16">
                                                                        <select
                                                                            className="form-input max-w-full md:max-w-[50%] h-11 text-black w-full py-3 px-5 md:mx-0 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                                            id={`player${index}Branch`}
                                                                            {...register(
                                                                                `players.${index}.branch`,
                                                                                {
                                                                                    required: `Player ${
                                                                                        index +
                                                                                        1
                                                                                    } branch is required`,
                                                                                }
                                                                            )}
                                                                        >
                                                                            <option
                                                                                value=""
                                                                                className="bh-white"
                                                                            >
                                                                                Select
                                                                                Branch
                                                                            </option>
                                                                            <option
                                                                                value="AIDS"
                                                                                className="bh-white"
                                                                            >
                                                                                AIDS
                                                                            </option>
                                                                            <option
                                                                                value="COMPS"
                                                                                className="bh-white"
                                                                            >
                                                                                COMPS
                                                                            </option>
                                                                            <option
                                                                                value="IT"
                                                                                className="bh-white"
                                                                            >
                                                                                IT
                                                                            </option>
                                                                            <option
                                                                                value="EXTC"
                                                                                className="bh-white"
                                                                            >
                                                                                EXTC
                                                                            </option>
                                                                            <option
                                                                                value="CHEM"
                                                                                className="bh-white"
                                                                            >
                                                                                CHEM
                                                                            </option>
                                                                        </select>
                                                                        {errors
                                                                            .players?.[
                                                                            index
                                                                        ]
                                                                            ?.branch && (
                                                                            <div className="form-error text-red-500 mt-1 md:ml-12 text-sm lg:ml-2">
                                                                                {
                                                                                    errors
                                                                                        .players[
                                                                                        index
                                                                                    ]
                                                                                        ?.branch
                                                                                        .message
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="flex md:flex-row flex-col md:items-baseline items-left">
                                                                    <label
                                                                        className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl md:mr-3 font-[atma]"
                                                                        htmlFor={`player${index}Division`}
                                                                    >
                                                                        Division
                                                                    </label>
                                                                    <div className="flex flex-col w-full items-start md:ml-16">
                                                                        <input
                                                                            className="form-input max-w-full md:max-w-[50%] h-11 text-black w-full py-3 px-5 md:mx-0 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                                            type="text"
                                                                            id={`player${index}Division`}
                                                                            {...register(
                                                                                `players.${index}.division`,
                                                                                {
                                                                                    required: `Player ${
                                                                                        index +
                                                                                        1
                                                                                    } division is required`,
                                                                                }
                                                                            )}
                                                                        />
                                                                        {errors
                                                                            .players?.[
                                                                            index
                                                                        ]
                                                                            ?.division && (
                                                                            <div className="form-error text-red-500 mt-1 md:ml-12 text-sm lg:ml-2">
                                                                                {
                                                                                    errors
                                                                                        .players[
                                                                                        index
                                                                                    ]
                                                                                        ?.division
                                                                                        .message
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="flex md:flex-row flex-col md:items-baseline items-left">
                                                                    {' '}
                                                                    <label
                                                                        className="form-label whitespace-nowrap md:mb-5 text-lg md:text-3xl md:mr-4 font-[atma]"
                                                                        htmlFor={`player${index}RollNumber`}
                                                                    >
                                                                        Roll
                                                                        Number
                                                                    </label>
                                                                    <div className="flex flex-col w-full items-start ">
                                                                        <input
                                                                            className="form-input max-w-full md:max-w-[50%] h-11 text-black w-full py-3 px-5 md:mx-0 md:mt-1 md:mr-0 inline-block rounded-md  focus:border-white text-base bg-none border-b-black"
                                                                            type="number"
                                                                            min="1"
                                                                            id={`player${index}RollNumber`}
                                                                            {...register(
                                                                                `players.${index}.rollNumber`,
                                                                                {
                                                                                    required: `Player ${
                                                                                        index +
                                                                                        1
                                                                                    } roll number is required`,
                                                                                }
                                                                            )}
                                                                        />
                                                                        {errors
                                                                            .players?.[
                                                                            index
                                                                        ]
                                                                            ?.rollNumber && (
                                                                            <div className="form-error text-red-500 mt-1 md:ml-12 text-sm lg:ml-2">
                                                                                {
                                                                                    errors
                                                                                        .players[
                                                                                        index
                                                                                    ]
                                                                                        ?.rollNumber
                                                                                        .message
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            )}
                                        </div>
                                    )}
                                    {step === 1 && (
                                        <>
                                            <div className="flex flex-row justify-end gap-2 md:px-12">
                                                <button
                                                    className="form-button w-full md:w-5/6  text-[#520d0d] py-3 px-2 mt-4 mb-8 md:mt-8 md:mr-0 bg-white inline-block rounded-2xl box-border focus:border-white text-xl md:text-3xl font-bold splFont"
                                                    onClick={() => {
                                                        prevStep(step);
                                                    }}
                                                >
                                                    Previous
                                                </button>
                                                <button
                                                    className="form-button w-full md:w-5/6  text-[#520d0d] py-3 px-2 mt-4 mb-8 md:mt-8 md:mr-0 bg-white inline-block rounded-2xl box-border focus:border-white text-xl md:text-3xl font-bold splFont"
                                                    onClick={() => {
                                                        nextStep(step);
                                                    }}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </>
                                    )}

                                    {step === 2 && (
                                        <div className="flex flex-row gap-2 md:px-8">
                                            <button
                                                className="form-button w-full md:w-5/6  text-[#520d0d] py-3 px-2 mt-4 mb-8 md:mt-8 md:mr-0 bg-white inline-block rounded-2xl box-border focus:border-white text-xl md:text-3xl font-bold splFont"
                                                onClick={() => {
                                                    prevStep(step);
                                                }}
                                            >
                                                Previous
                                            </button>
                                            <button
                                                className={`form-button w-full md:w-5/6  text-[#520d0d] py-3 px-2 mt-4 mb-8 md:mt-8 md:mr-0 bg-white inline-block rounded-2xl box-border focus:border-white text-xl md:text-3xl font-bold splFont ${
                                                    isSubmitting
                                                        ? 'opacity-50 cursor-not-allowed'
                                                        : ''
                                                }`}
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                {isSubmitting
                                                    ? 'Submitting..'
                                                    : 'Submit'}
                                            </button>
                                        </div>
                                    )}
                                </form>
                            </>
                        )}
                    </div>
                    {isSubmitted && (
                        <Confetti
                            width={window.width}
                            height={window.height}
                            numberOfPieces={1500}
                            recycle={false}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Newbiethon;
